.btn {
	border: 2px solid $orange;
	border-radius: 10px;
	background-color: transparent;
	min-width: 274px;
	height: 40px;
	font-size: 11px;
	font-weight: 900;
	line-height: 11px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: $orange;
	padding: 0 20px;
	justify-content: center;
	align-items: center;
	display: inline-flex;
	@include media($w1180) {
		height: 38px;
		letter-spacing: 0.1em;
		padding: 0 18px;
	}
	@include media($w1024) {
		min-width: 265px;
	}
	@include media($w560) {
		min-width: 0px;
	}
	&:hover {
		background-color: $orange;
		color: $white;
	}
	&--full {
		background-color: $orange;
		color: $white;
		&:hover {
			background-color: transparent;
			color: $orange;
		}
	}
	&--white {
		border-color: $white;
		color: $white;
	}
}
