.contact {
  margin: 0 0 60px;
  position: relative;
  @include media($w560) {
    margin: 0 0 50px;
  }
  &__wrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    h4 {
      color: $orange;
      margin: 0 0 90px;
      @include media($w1366) {
        margin: 0 0 75px;
      }
      @include media($w1180) {
        margin: 0 0 60px;
      }
      @include media($w1024) {
        margin: 0 0 50px;
      }
      @include media($w768) {
        margin: 0 0 40px;
      }
    }
  }
  &__copy {
    flex: 0 0 35%;
    position: relative;
    @include media($w1180) {
      flex: 0 0 calc(50% - 30px);
    }
    @include media($w1024) {
      flex: 0 0 calc(40% - 15px);
    }
    @include media($w768) {
      flex: 0 0 100%;
      margin: 0 0 40px;
    }
  }
  &__form {
    flex: 0 0 50%;
    position: relative;
    @include media($w1024) {
      flex: 0 0 calc(60% - 15px);
    }
    @include media($w768) {
      flex: 0 0 100%;
    }
    form {
      margin: -6px 0 0;
      @include media($w768) {
        margin: -5px 0 0;
      }
    }
  }
}
