.testimonials {
  margin: 0 0 140px;
  position: relative;
  @include media($w1600) {
    margin: 0 0 130px;
  }
  @include media($w1366) {
    margin: 0 0 120px;
  }
  @include media($w1180) {
    margin: 0 0 110px;
  }
  @include media($w1024) {
    margin: 0 0 100px;
  }
  @include media($w768) {
    margin: 0 0 90px;
  }
  @include media($w560) {
    margin: 0 0 80px;
  }
  &__header {
    color: $orange;
    margin: 0 0 145px;
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    @include media($w1600) {
  		margin: 0 0 135px;
  	}
    @include media($w1024) {
  		margin: 0 0 125px;
  	}
    @include media($w768) {
  		margin: 0 0 80px;
      flex-wrap: wrap;
  	}
    @include media($w560) {
  		margin: 0 0 65px;
  	}
    h2 {
      @include media($w768) {
        flex: 0 0 100%;
    	}
    }
    .testimonials {
      &__nav {
        @include media($w768) {
          display: none;
      	}
      }
    }
  }
  &__slider {
    margin: 0 0 90px;
    overflow: visible !important;
    @include media($w1024) {
  		margin: 0 0 120px;
  	}
    @include media($w768) {
  		margin: 0 0 80px;
  	}
    @include media($w560) {
  		margin: 0 0 60px;
  	}
    &.desktop {
      @include media($w1024) {
        display: none;
      }
    }
    &.mobile {
      display: none;
      @include media($w1024) {
        display: block;
      }
    }
  }
  &__item {
    @include transition;
    opacity: 0 !important;
    &.swiper-slide-active {
      opacity: 1 !important;
    }
    &-person {
      align-items: center;
      display: flex;
      @include media($w560) {
        flex-wrap: wrap;
      }
      &:last-child {
        margin: 115px 0 0;
        justify-content: flex-end;
        @include media($w1600) {
          margin: 100px 0 0;
        }
        @include media($w1366) {
          margin: 80px 0 0;
        }
        @include media($w1180) {
          margin: 60px 0 0;
        }
        @include media($w1024) {
          margin: 0;
        }
        @include media($w560) {
          justify-content: flex-start;
        }
      }
      &-photo {
        border-radius: 50%;
        flex: 0 0 400px;
        height: 400px;
        margin: 0 60px 0 0;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        display: flex;
        @include media($w1600) {
          flex: 0 0 380px;
          height: 380px;
        }
        @include media($w1366) {
          flex: 0 0 350px;
          height: 350px;
          margin: 0 50px 0 0;
        }
        @include media($w1180) {
          flex: 0 0 320px;
          height: 320px;
          margin: 0 30px 0 0;
        }
        @include media($w1024) {
          flex: 0 0 290px;
          height: 290px;
        }
        @include media($w768) {
          flex: 0 0 240px;
          height: 240px;
        }
        @include media($w560) {
          border-radius: 50% 50% 50% 0;
          flex: 0 0 150px;
          height: 150px;
          margin: 0 0 35px;
        }
        img {
          width: 100%;
        }
      }
      &-copy {
        max-width: 460px;
        @include media($w1366) {
          max-width: 450px;
        }
        @include media($w1366) {
          max-width: 520px;
        }
        @include media($w1024) {
          max-width: calc(100% - 320px);
        }
        @include media($w768) {
          max-width: calc(100% - 270px);
        }
        @include media($w560) {
          max-width: 100%;
        }
        h4 {
          margin: 0 0 75px;
          @include media($w1600) {
        		margin: 0 0 60px;
        	}
          @include media($w1366) {
        		margin: 0 0 45px;
        	}
          @include media($w1180) {
        		margin: 0 0 40px;
        	}
          @include media($w1024) {
            font-size: 24px;
          	line-height: 24px;
        		margin: 0 0 35px;
        	}
          @include media($w768) {
            font-size: 22px;
          	line-height: 22px;
        		margin: 0 0 30px;
        	}
          @include media($w560) {
            font-size: 20px;
          	line-height: 20px;
            margin: 0 0 25px;
        	}
        }
        &-signature {
          font-size: 16px;
          font-weight: 900;
          line-height: 20px;
          letter-spacing: -0.05em;
          span {
            color: $orange;
          }
        }
      }
    }
  }
}
