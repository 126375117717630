@font-face {
	font-family: 'Arial';
	src: url('../fonts/ArialMT.woff2') format('woff2'),
			 url('../fonts/ArialMT.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Arial';
	src: url('../fonts/Arial-BoldMT.woff2') format('woff2'),
			 url('../fonts/Arial-BoldMT.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Arial';
	src: url('../fonts/Arial-Black.woff2') format('woff2'),
			 url('../fonts/Arial-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}
