label {
	height: 40px;
	font-size: 16px;
	font-weight: 900;
	line-height: 20px;
	align-items: center;
	display: inline-flex;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
	@include transition;
	border: 0;
	border-bottom: 2px solid $black;
	border-radius: 0;
	background-color: transparent;
	width: 100%;
	height: 40px;
	font-family: 'Arial' !important;
	font-size: 16px;
	font-weight: 900;
	line-height: 20px;
	color: $black;
	outline: none;
	margin: 0;
	padding: 0 8px;
	box-shadow: none !important;
	-webkit-appearance: none;
	@include media($w560) {
		padding: 0;
	}
	&:focus {
		border-color: $black;
		background-color: transparent;
	}
}

input[type="submit"] {
	@include transition;
	border: 2px solid $orange;
	border-radius: 10px;
	background-color: $orange;
	min-width: 274px;
	height: 40px;
	font-size: 11px;
	font-weight: 900;
	line-height: 11px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: $white;
	cursor: pointer;
	padding: 0 20px;
	-webkit-appearance: none;
	@include media($w1180) {
		height: 38px;
		letter-spacing: 0.1em;
		padding: 0 18px;
	}
	@include media($w1024) {
		min-width: 265px;
	}
	@include media($w560) {
		min-width: 0px;
	}
	&:hover {
		background-color: transparent;
		color: $orange;
	}
}

::-webkit-input-placeholder {
	color: $black !important;
	opacity: 0.25;
}

:-moz-placeholder {
	color: $black !important;
	opacity: 0.25;
}

::-moz-placeholder {
	color: $black !important;
	opacity: 0.25;
}

:-ms-input-placeholder {
	color: $black !important;
	opacity: 0.25;
}

button {
	@include transition;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	font-family: 'Arial' !important;
	cursor: pointer;
	margin: 0;
	padding: 0;
	-webkit-appearance: none;
}

.input-group {
	display: flex;
	@include media($w560) {
    flex-wrap: wrap;
  }
	& + .input-group {
		@include media($w560) {
			margin: 15px 0 0;
		}
	}
	label {
		flex: 0 0 140px;
		@include media($w560) {
	    flex: 0 0 100%;
			margin: 0 0 -5px;
	  }
	}
	&__input {
		flex: 1 1 auto;
		@include media($w560) {
	    flex: 0 0 100%;
	  }
	}
}

.checkbox-group {
	padding: 18px 0 0 140px;
	@include media($w560) {
		padding: 16px 0 0;
	}
	label {
		cursor: pointer;
		align-items: center;
		display: flex;
	}
}

.submit-group {
	margin: 22px 0 0;
	justify-content: flex-end;
	display: flex;
	@include media($w560) {
		margin: 20px 0 0;
	}
	button {
		@include media($w560) {
			flex: 0 0 100%;
		}
	}
}
