.nav {
	align-items: center;
	display: flex;
	h6 {
		color: $orange;
		margin: 0 20px 0 0;
	}
	&__prev,
	&__next {
		cursor: pointer;
		img {
			width: 9px;
		}
	}
	&__prev {
		margin: 0 20px 0 0;
	}
}
