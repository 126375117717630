.our-works {
  margin: 0 0 230px;
  position: relative;
  @include media($w1600) {
    margin: 0 0 200px;
  }
  @include media($w1366) {
    margin: 0 0 180px;
  }
  @include media($w1180) {
    margin: 0 0 160px;
  }
  @include media($w1024) {
    margin: 0 0 140px;
  }
  @include media($w768) {
    margin: 0 0 120px;
  }
  @include media($w560) {
    margin: 0 0 100px;
  }
  &__header {
    text-align: center;
    color: $orange;
    margin: 0 0 90px;
  }
  &__slider {
    @include media($w1024) {
      padding: 0 0 80px;
    }
    &.desktop {
      @include media($w1024) {
        display: none;
      }
    }
    &.mobile {
      display: none;
      @include media($w1024) {
        display: block;
      }
    }
  }
  &__item {
    @include transition;
    pointer-events: none !important;
    opacity: 0 !important;
    &.swiper-slide-active {
      opacity: 1 !important;
    }
    &-work {
      width: calc(50% - 30px);
      margin: -135px 0 0;
      position: relative;
      @include media($w1600) {
        width: calc(50% - 25px);
        margin: -125px 0 0;
      }
      @include media($w1366) {
        width: calc(50% - 20px);
        margin: -110px 0 0;
      }
      @include media($w1180) {
        width: calc(50% - 15px);
        margin: -100px 0 0;
      }
      @include media($w1024) {
        width: 100%;
        margin: 0;
        padding: 0;
      }
      &:first-child {
        margin: 0;
      }
      &:nth-child(even) {
        margin: -285px 0 0 auto;
        @include media($w1600) {
          margin: -260px 0 0 auto;
        }
        @include media($w1366) {
          margin: -250px 0 0 auto;
        }
        @include media($w1180) {
          margin: -240px 0 0 auto;
        }
      }
      &-image {
        background-size: cover;
        background-position: center center;
        width: calc(100% - 70px);
        height: 285px;
        @include media($w1600) {
          height: 260px;
        }
        @include media($w1366) {
          width: calc(100% - 60px);
          height: 250px;
        }
        @include media($w1180) {
          width: calc(100% - 50px);
          height: 240px;
        }
        @include media($w1024) {
          width: calc(100% - 30px);
          height: 250px;
        }
        @include media($w560) {
          border-radius: 10px;
          width: 100%;
          height: 230px;
        }
        &-link {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 150;
          top: 0;
          left: 0;
          &:first-child {
            z-index: 200;
          }
        }
      }
      &-info {
        @include transition;
        border-radius: 10px;
        background-color: $orange;
        width: calc(100% - 100px);
        min-height: 285px;
        color: $white;
        margin: -50px 0 0 auto;
        padding: 50px;
        position: relative;
        z-index: 50;
        @include media($w1600) {
          width: calc(100% - 70px);
          min-height: 255px;
          padding: 45px 40px;
        }
        @include media($w1366) {
          width: calc(100% - 60px);
          min-height: 250px;
        }
        @include media($w1180) {
          width: calc(100% - 50px);
          min-height: 240px;
          margin: -40px 0 0 auto;
        }
        @include media($w1024) {
          width: calc(100% - 30px);
          min-height: 0px;
          padding: 25px 30px 35px;
        }
        @include media($w560) {
          width: 100%;
          margin: -10px 0 0;
        }
        h2 {
          margin: 0 0 50px;
          @include media($w1600) {
            margin: 0 0 45px;
          }
          @include media($w1366) {
            margin: 0 0 40px;
          }
          @include media($w1180) {
            margin: 0 0 42px;
          }
          @include media($w1024) {
            margin: 0 0 25px;
          }
        }
        ul {
          flex-wrap: wrap;
          display: flex;
          li {
            font-size: 16px;
            font-weight: 900;
            line-height: 20px;
            letter-spacing: -0.06em;
            margin: 0 40px 0 0;
            position: relative;
            @include media($w560) {
              font-size: 15px;
              line-height: 19px;
              margin: 0 30px 0 0;
            }
            &:after {
              content: '.';
              width: 40px;
              text-align: center;
              position: absolute;
              right: -40px;
              @include media($w560) {
                width: 30px;
                right: -30px;
              }
            }
            &:last-child {
              margin: 0;
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
    &.swiper-slide-active {
      .our-works {
        &__item {
          &-work {
            &-image {
              &-link {
                pointer-events: auto;
              }
            }
          }
        }
      }
    }
  }
  &__nav {
    position: absolute;
    z-index: 150;
    top: 0;
    right: 0;
    @include media($w1024) {
      top: auto;
      bottom: 0;
    }
    @include media($w560) {
      right: auto;
      left: 0;
    }
    &--bottom {
      top: auto;
      bottom: 10px;
      right: calc(50% + 30px);
      @include media($w1600) {
        right: calc(50% + 25px);
      }
      @include media($w1366) {
        right: calc(50% + 20px);
      }
      @include media($w1180) {
        right: calc(50% + 15px);
      }
      @include media($w1024) {
        display: none;
      }
    }
  }
  &__footer {
    pointer-events: none;
    margin: -40px 0 0;
    position: relative;
    z-index: 25;
    @include media($w1024) {
      margin: 55px 0 0;
    }
    .btn {
      pointer-events: auto;
      @include media($w560) {
        width: 100%;
      }
    }
  }
}
