.hero {
  margin: 0 0 310px;
  padding: 400px 0 0;
  @include media($w1600) {
    margin: 0 0 260px;
    padding: 350px 0 0;
	}
  @include media($w1366) {
    margin: 0 0 200px;
    padding: 300px 0 0;
	}
  @include media($w1180) {
    margin: 0 0 175px;
    padding: 250px 0 0;
	}
  @include media($w1024) {
    margin: 0 0 150px;
    padding: 200px 0 0;
	}
  @include media($w768) {
    margin: 0 0 125px;
    padding: 125px 0 0;
	}
  @include media($w560) {
    margin: 0 0 100px;
    padding: 100px 0 0;
	}
  &__wrapper {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    @include media($w768) {
      flex-wrap: wrap;
  	}
    h1 {
      color: $orange;
      padding: 0 60px 0 0;
      @include media($w1180) {
        padding: 0 30px 0 0;
      }
      @include media($w768) {
        flex: 0 0 100%;
        margin: 0 0 40px;
        padding: 0;
    	}
      span {
        color: $black;
      }
      br {
        @include media($w560) {
          display: none;
      	}
      }
    }
    .btn {
      margin: 0 0 3px;
      @include media($w1366) {
        margin: 0 0 1px;
    	}
      @include media($w1024) {
        margin: 0 0 -3px;
    	}
    }
  }
}
