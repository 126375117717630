.what-we-do {
  margin: 0 0 380px;
  position: relative;
  @include media($w1600) {
    margin: 0 0 300px;
  }
  @include media($w1366) {
    margin: 0 0 220px;
  }
  @include media($w1180) {
    margin: 0 0 180px;
  }
  @include media($w1024) {
    margin: 0 0 140px;
  }
  @include media($w768) {
    margin: 0 0 110px;
  }
  @include media($w560) {
    margin: 0 0 80px;
  }
  &__wrapper {
    padding: 0 100px;
    @include media($w1600) {
      padding: 0 75px;
    }
    @include media($w1366) {
      padding: 0 50px;
    }
    @include media($w1180) {
      padding: 0;
    }
  }
  &__content {
    position: relative;
    z-index: 25;
  }
  &__item {
    display: flex;
    position: relative;
    & + .what-we-do {
      &__item {
        margin: 260px 0 0;
        @include media($w1600) {
          margin: 220px 0 0;
        }
        @include media($w1366) {
          margin: 170px 0 0;
        }
        @include media($w1180) {
          margin: 140px 0 0;
        }
        @include media($w1024) {
          margin: 110px 0 0;
        }
        @include media($w768) {
          margin: 90px 0 0;
        }
        @include media($w560) {
          margin: 70px 0 0;
        }
      }
    }
    &-copy {
      flex: 0 0 420px;
      @include media($w1024) {
        flex: 0 0 390px;
      }
      @include media($w768) {
        flex: 0 0 70%;
      }
      @include media($w560) {
        flex: 0 0 calc(100% - 45px);
      }
      h4 {
        color: $orange;
        margin: 0 0 22px;
      }
    }
    &-addon {
      width: 300px;
      height: 300px;
      text-align: right;
      pointer-events: none;
      transform-origin: right top;
      transform: rotate(-90deg);
      position: absolute;
      top: 0;
      left: -300px;
      span {
        font-size: 12px;
        font-weight: 900;
        line-height: 12px;
        color: $orange;
        pointer-events: auto;
        display: inline-block;
        position: relative;
        &::before {
          @include translateY;
          content: '';
          background-color: $orange;
          width: 100px;
          height: 2px;
          position: absolute;
          right: calc(100% + 20px);
          @include media($w1180) {
            width: 85px;
          }
          @include media($w768) {
            width: 60px;
          }
        }
      }
    }
    &:nth-child(2n+1) {
      justify-content: flex-end;
    }
    &:nth-child(2n+2) {
      @include media($w768) {
        justify-content: flex-end;
      }
      .what-we-do {
        &__item {
          &-addon {
            left: auto;
            right: 25px;
            @include media($w768) {
              left: -300px;
              right: auto;
            }
          }
        }
      }
    }
    &:last-child {
      .what-we-do {
        &__item {
          &-addon {
            display: none;
            @include media($w768) {
              display: block;
            }
          }
        }
      }
    }
  }
  &__footer {
    margin: -100px 0 0;
    pointer-events: none;
    position: relative;
    z-index: 50;
    @include media($w1180) {
      margin: -95px 0 0;
    }
    @include media($w768) {
      margin: 90px 0 0;
    }
    @include media($w560) {
      margin: 70px 0 0;
    }
    &-item {
      & + .what-we-do {
        &__footer {
          &-item {
            margin: 20px 0 0;
            @include media($w1180) {
              margin: 15px 0 0;
            }
          }
        }
      }
      .btn {
        pointer-events: auto;
        @include media($w560) {
          width: 100%;
        }
      }
    }
  }
}
