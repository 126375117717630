.menu-trigger {
  @include transition;
  background-color: $orange;
  width: 100%;
  height: 46px;
	font-size: 11px;
	font-weight: 900;
	line-height: 11px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
  color: $white;
  cursor: pointer;
  padding: 0 30px;
  justify-content: space-between;
  align-items: center;
  display: none;
  position: fixed;
  z-index: 250;
  bottom: 0;
  left: 0;
  @include media($w1024) {
    display: flex;
  }
  span {
    @include transition;
    background-color: $white;
    width: 20px;
    height: 1px;
    position: relative;
    &:before,
    &:after {
      @include transition;
      content: '';
      background-color: $white;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
    }
    &:before {
      top: -5px;
    }
    &:after {
      bottom: -5px;
    }
  }
  &.open {
    span {
      background-color: transparent;
      &:before {
        transform: rotate(45deg);
        top: 0;
      }
      &:after {
        transform: rotate(-45deg);
        bottom: 0;
      }
    }
  }
}

.menu {
  @include transition;
  background-color: $orange;
  width: 100%;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  padding: 0 0 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 200;
  top: 50px;
  left: 0;
  ul {
    flex-wrap: wrap;
    display: flex;
    li {
      flex: 0 0 100%;
      text-align: center;
      color: $white;
      & + li {
        margin: 15px 0 0;
      }
      &:last-child {
        margin: 40px 0 0;
      }
      a {
        font-size: 11px;
        font-weight: 900;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 0.25em;
        color: $white;
      }
      span {
        font-size: 11px;
        font-weight: 900;
        line-height: 30px;
        margin: 0 10px 0 6px;
        display: inline-block;
        &:last-child {
          display: none;
        }
      }
      &.current {
        a {
          color: $white;
        }
      }
    }
  }
  &.show {
    opacity: 1;
    pointer-events: auto;
    top: 0;
  }
}
