a {
	@include transition;
	text-decoration: none;
	color: $orange;
	&:hover {
		color: $black;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 900;
	letter-spacing: -0.05em;
	margin: 0;
}

h1 {
	font-size: 60px;
	line-height: 55px;
	@include media($w1600) {
		font-size: 55px;
		line-height: 50px;
	}
	@include media($w1366) {
		font-size: 50px;
		line-height: 45px;
	}
	@include media($w1180) {
		font-size: 45px;
		line-height: 40px;
	}
	@include media($w1024) {
		font-size: 40px;
		line-height: 35px;
	}
	@include media($w768) {
		font-size: 33px;
		line-height: 33px;
	}
}

h2 {
	font-size: 60px;
	line-height: 55px;
	@include media($w1600) {
		font-size: 55px;
		line-height: 50px;
	}
	@include media($w1366) {
		font-size: 50px;
		line-height: 45px;
	}
	@include media($w1180) {
		font-size: 45px;
		line-height: 40px;
	}
	@include media($w1024) {
		font-size: 40px;
		line-height: 35px;
	}
	@include media($w768) {
		font-size: 35px;
		line-height: 35px;
	}
}

h3 {
	font-size: 60px;
	line-height: 55px;
	@include media($w1600) {
		font-size: 55px;
		line-height: 50px;
	}
	@include media($w1366) {
		font-size: 50px;
		line-height: 45px;
	}
	@include media($w1180) {
		font-size: 45px;
		line-height: 40px;
	}
	@include media($w1024) {
		font-size: 40px;
		line-height: 35px;
	}
	@include media($w768) {
		font-size: 35px;
		line-height: 35px;
	}
}

h4 {
	font-size: 30px;
	line-height: 30px;
	@include media($w1600) {
		font-size: 28px;
		line-height: 28px;
	}
	@include media($w1180) {
		font-size: 26px;
		line-height: 26px;
	}
	@include media($w560) {
		font-size: 24px;
		line-height: 24px;
	}
}

p {
	font-size: 16px;
	font-weight: 900;
	line-height: 28px;
	letter-spacing: -0.05em;
	margin: 0;
	@include media($w1024) {
		line-height: 26px;
	}
	@include media($w768) {
		line-height: 24px;
		text-align: left;
	}
	@include media($w560) {
		line-height: 22px;
	}
}

strong, b {
	font-weight: 700;
}

.c {
	* {
		&:last-child {
			margin: 0 !important;
		}
	}
}
