* {
	@include box-sizing;
	font-family: 'Arial' !important;
	&::before,
	&::after {
		@include box-sizing;
	}
}

::-moz-selection {
	background: $black;
	color: $white;
}

::selection {
	background: $black;
	color: $white;
}

body {
	background-color: $white;
	font-family: 'Arial' !important;
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	color: $black;
	margin: 0;
	padding: 0;
}

img {
	margin: 0;
	display: block;
	user-select: none;
}

/* --- [ global styles ] --- */

.wrapper {
	width: 100%;
	overflow: hidden;
	padding: 100px 0 0;
	position: relative;
	@include media($w1180) {
    padding: 90px 0 0;
  }
}

.container {
	max-width: 1250px;
	margin: 0 auto;
	padding: 0 25px;
	position: relative;
}

.clearfix {
	&::after {
		content: '';
		clear: both;
		display: table;
	}
}

.anchor {
	width: 100%;
	height: 1px;
	pointer-events: none;
	position: absolute;
	top: -200px;
	left: 0;
	@include media($w1180) {
    top: -180px;
  }
	@include media($w768) {
    top: -170px;
  }
}
