.letters {
  position: relative;
  z-index: 50;
  &__item {
    position: absolute;
    @include media($w1024) {
      display: none;
    }
    img {
      width: 100%;
    }
    &--g {
      width: (2000 * 100vw / 1920);
      z-index: -25;
      top: -470px;
      right: (400 * 100vw / 1920);
      @include media($w1600) {
        top: -370px;
      }
      @include media($w1366) {
        top: -340px;
      }
      @include media($w1180) {
        top: -260px;
      }
    }
    &--r {
      width: (2075 * 100vw / 1920);
      z-index: -50;
      top: 6.25%;
      left: (365 * 100vw / 1920);
      @include media($w1600) {
        top: 6.5%;
      }
      @include media($w1366) {
        top: 10%;
      }
      @include media($w1180) {
        top: 12%;
      }
    }
    &--o {
      width: (2065 * 100vw / 1920);
      z-index: -25;
      top: 30%;
      right: (365 * 100vw / 1920);
      @include media($w1600) {
        top: 32%;
      }
      @include media($w1366) {
        top: 33%;
      }
      @include media($w1180) {
        top: 34.5%;
      }
    }
    &--w {
      width: (2905 * 100vw / 1920);
      z-index: -50;
      top: 48%;
      left: (320 * 100vw / 1920);
      @include media($w1600) {
        top: 51%;
      }
      @include media($w1366) {
        top: 52%;
      }
      @include media($w1180) {
        top: 53%;
      }
    }
    &--n {
      width: (2005 * 100vw / 1920);
      z-index: -25;
      bottom: -125px;
      right: (150 * 100vw / 1920);
      @include media($w1600) {
        bottom: -100px;
      }
      @include media($w1366) {
        bottom: -90px;
      }
      @include media($w1180) {
        right: (250 * 100vw / 1920);
      }
    }
  }
}
