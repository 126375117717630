.ajax-loader {
  display: none !important;
}

.wpcf7-not-valid-tip {
  font-size: 14px !important;
  font-weight: 900 !important;
  line-height: 20px !important;
  margin: 4px 0 -4px !important;
}

.wpcf7-response-output {
  font-size: 14px !important;
  font-weight: 900 !important;
  line-height: 20px !important;
  margin: 40px 0 0 !important;
  padding: 8px 20px 10px !important;
}

.wpcf7-list-item {
  margin: 0 !important;
  input[type="checkbox"] {
    margin: 0 10px 0 0 !important;
  }
}
