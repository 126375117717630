.footer {
  padding: 0 0 100px;
  @include media($w1600) {
    padding: 0 0 90px;
  }
  @include media($w1366) {
    padding: 0 0 80px;
  }
  @include media($w1024) {
    padding: 0 0 110px;
  }
  @include media($w768) {
    padding: 0 0 100px;
  }
  &__wrapper {
    margin: 0 0 90px;
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    @include media($w1600) {
      margin: 0 0 80px;
    }
    @include media($w1366) {
      margin: 0 0 70px;
    }
    @include media($w1024) {
      margin: 0 0 55px;
    }
    @include media($w768) {
      margin: 0 0 50px;
      justify-content: center;
    }
  }
  &__data,
  &__map {
    flex: 0 0 50%;
    @include media($w1024) {
      flex: 0 0 calc(40% - 15px);
    }
    @include media($w768) {
      flex: 0 0 100%;
    }
  }
  &__data {
    @include media($w768) {
      margin: 0 0 55px;
    }
    p {
      margin: 0 0 28px;
      @include media($w1024) {
        margin: 0 0 20px;
      }
      @include media($w768) {
        margin: 0 0 15px;
      }
      a {
        color: $black;
        &:hover {
          color: $orange;
        }
      }
    }
    &-social {
      margin: 70px 0 0;
      display: flex;
      @include media($w1024) {
        margin: 50px 0 0;
      }
      @include media($w768) {
        margin: 25px 0 0;
      }
      li {
        & + li {
          margin: 0 0 0 20px;
        }
        img {
          height: 21px;
        }
      }
    }
  }
  &__map {
    position: relative;
    @include media($w1024) {
      flex: 0 0 calc(60% - 15px);
    }
    @include media($w768) {
      flex: 0 0 80%;
    }
    @include media($w560) {
      flex: 0 0 100%;
    }
    &-text {
      font-size: 14px;
      font-weight: 900;
      line-height: 18px;
      color: $orange;
      letter-spacing: -0.05em;
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
    }
    &-img {
      position: relative;
      z-index: 25;
      img {
        width: 100%;
      }
    }
  }
  &__copy {
    font-size: 14px;
  	font-weight: 900;
  	line-height: 26px;
    text-align: right;
    @include media($w1024) {
      line-height: 24px;
    }
    @include media($w768) {
      line-height: 22px;
      text-align: left;
    }
    @include media($w560) {
      line-height: 20px;
    }
  }
}
