.header {
  @include transition;
  width: 100%;
  height: 100px;
  padding: 0 45px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  @include media($w1600) {
    padding: 0 40px;
  }
  @include media($w1366) {
    padding: 0 30px;
  }
  @include media($w1180) {
    height: 90px;
  }
  &__logo {
    flex: 0 0 120px;
    position: relative;
    @include media($w1366) {
      flex: 0 0 100px;
    }
    img {
      @include translateY;
      width: 100%;
      position: absolute;
      left: 0;
    }
  }
  &__menu {
    margin: 0 30px 0 auto;
    @include media($w1600) {
      margin: 0 16px 0 auto;
    }
    @include media($w1366) {
      margin: 0 11px 0 auto;
    }
    @include media($w1180) {
      margin: 0 8px 0 auto;
    }
    @include media($w1024) {
      display: none;
    }
    ul {
      display: flex;
      li {
        & + li {
          margin: 0 0 0 40px;
          @include media($w1600) {
            margin: 0 0 0 32px;
          }
          @include media($w1366) {
            margin: 0 0 0 22px;
          }
          @include media($w1180) {
            margin: 0 0 0 16px;
          }
        }
        a {
          font-size: 11px;
          font-weight: 900;
          line-height: 50px;
          text-transform: uppercase;
          letter-spacing: 0.4em;
          @include media($w1600) {
            letter-spacing: 0.35em;
          }
          @include media($w1366) {
            letter-spacing: 0.25em;
          }
          @include media($w1180) {
            letter-spacing: 0.1em;
          }
        }
        &.current {
          a {
            color: $black;
          }
        }
      }
    }
  }
  &__languages {
    margin: 0 auto 0 30px;
    @include media($w1600) {
      margin: 0 auto 0 16px;
    }
    @include media($w1366) {
      margin: 0 auto 0 11px;
    }
    @include media($w1180) {
      margin: 0 auto 0 8px;
    }
    @include media($w1024) {
      display: none;
    }
    a {
      font-size: 11px;
      font-weight: 900;
      line-height: 50px;
      text-transform: uppercase;
      letter-spacing: 0.4em;
      @include media($w1600) {
        letter-spacing: 0.3em;
      }
      @include media($w1366) {
        letter-spacing: 0.2em;
      }
      @include media($w1180) {
        letter-spacing: 0.10em;
      }
    }
    span {
      font-size: 11px;
      font-weight: 900;
      line-height: 50px;
      color: $orange;
      margin: 0 10px 0 6px;
      display: inline-block;
      @include media($w1366) {
        margin: 0 8px 0 4px;
      }
      &:last-child {
        display: none;
      }
    }
  }
  &__button {
    .btn {
    	min-width: 232px;
      @include media($w1600) {
        min-width: 220px;
      }
      @include media($w1366) {
        min-width: 0px;
      }
    }
  }
  &.sticky {
    background-color: $white;
    box-shadow: 0px 5px 10px 0px rgba($black,0.05);
  }
}
