.approach {
  margin: 0 0 140px;
  position: relative;
  @include media($w768) {
    margin: 0 0 125px;
  }
  @include media($w560) {
    margin: 0 0 100px;
  }
  &__header {
    margin: 0 0 310px;
    @include media($w1600) {
      margin: 0 0 300px;
    }
    @include media($w1366) {
      margin: 0 0 220px;
    }
    @include media($w1180) {
      margin: 0 0 180px;
    }
    @include media($w1024) {
      margin: 0 0 140px;
    }
    @include media($w768) {
      margin: 0 0 110px;
    }
    @include media($w560) {
      margin: 0 0 80px;
    }
    h2 {
      color: $orange;
    }
  }
  &__content {
    position: relative;
    z-index: 25;
  }
  &__item {
    width: 520px;
    padding: 0 0 0 100px;
    position: relative;
    @include media($w1600) {
      padding: 0 0 0 75px;
    }
    @include media($w1366) {
      padding: 0 0 0 50px;
    }
    @include media($w1180) {
      width: calc(50% - 15px);
    }
    @include media($w768) {
      width: 100%;
      padding: 0 0 0 30%;
    }
    @include media($w560) {
      width: 100%;
      padding: 0 0 0 45px;
    }
    &:nth-child(2n+2) {
      margin: 0 0 0 auto;
      @include media($w1180) {
        margin: 30px 0 0 auto;
      }
      @include media($w768) {
        margin: 90px 0 0;
      }
      @include media($w560) {
        margin: 70px 0 0;
      }
    }
    & + .approach {
      &__item {
        @include media($w1180) {
          margin-top: 30px;
        }
        @include media($w768) {
          margin: 90px 0 0;
        }
        @include media($w560) {
          margin: 70px 0 0;
        }
      }
    }
    h4 {
      height: 145px;
      color: $orange;
      @include media($w1180) {
        height: 110px;
      }
      @include media($w1024) {
        height: 90px;
      }
      @include media($w768) {
        height: auto;
        margin: 0 0 22px;
      }
    }
    &-addon {
      width: 300px;
      height: 300px;
      text-align: right;
      pointer-events: none;
      transform-origin: right top;
      transform: rotate(-90deg);
      position: absolute;
      top: 0;
      left: -300px;
      span {
        font-size: 12px;
        font-weight: 900;
        line-height: 12px;
        color: $orange;
        pointer-events: auto;
        display: inline-block;
        position: relative;
        &::before {
          @include translateY;
          content: '';
          background-color: $orange;
          width: 100px;
          height: 2px;
          position: absolute;
          right: calc(100% + 20px);
          @include media($w1180) {
            width: 85px;
          }
          @include media($w1024) {
            width: 60px;
          }
          @include media($w768) {
            width: 30px;
          }
        }
        &::after {
          content: attr(data-number);
          font-size: 30px;
          line-height: 30px;
          text-align: center;
          cursor: text;
          transform: rotate(90deg);
          position: absolute;
          top: 0;
          right: calc(100% + 125px);
          @include media($w1180) {
            right: calc(100% + 110px);
          }
          @include media($w1024) {
            display: none;
          }
        }
      }
    }
  }
  &__footer {
    margin: -38px 0 0;
    pointer-events: none;
    position: relative;
    z-index: 50;
    @include media($w768) {
      margin: 90px 0 0;
    }
    @include media($w560) {
      margin: 70px 0 0;
    }
    .btn {
      pointer-events: auto;
      @include media($w560) {
        width: 100%;
      }
    }
  }
}
